import { initializeApp } from 'firebase/app'
import {
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from 'firebase/auth'

import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
	apiKey: "AIzaSyD7SQr6-u6n6HvFNgR6kZGT1r1nlWouMXs",
	authDomain: "redecarinho-autenticacao.firebaseapp.com",
	projectId: "redecarinho-autenticacao",
	storageBucket: "redecarinho-autenticacao.firebasestorage.app",
	messagingSenderId: "542167007958",
	appId: "1:542167007958:web:16d4647aeccde7e3093f77"
  };
  
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
getAnalytics(app)

export const signOutFirebase = async () => {
	await auth.signOut()
}

export const createNewUserFirebase = async (
	email: string,
	password: string,
) => {
	const userCredential = await createUserWithEmailAndPassword(
		auth,
		email,
		password,
	)
	return userCredential.user
}

export const loginUserFirebase = async (email: string, password: string) => {
	const userCredential = await signInWithEmailAndPassword(auth, email, password)
	return userCredential.user
}

export const resetPasswordFirebase = (email: string) => {
	sendPasswordResetEmail(auth, email)
}
